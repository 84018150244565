// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.
// temporarily replacing "dev" configs from "dev" configs

export const environment = {
    version: '2.000.0',
    production: false,
    errMsg: 'Failed to complete. Please try again.',
    partner: true,
    config: {
        "stage": "development",
        "appName": "custom-dashboard-ui",
        "appDisplayName":"75F",
        "caretakerUrl": "https://caretaker.dev.service.75f.io/api/v1",
        "haystackUrl": "https://silo.dev.service.75f.io/",
        "tagsUrl": "https://silo.dev.service.75f.io/v2/sitePointTags/",
        "tableMakerUrl": "https://table-maker-api.dev.service.75f.io/",
        'devCycleClientId':"dvc_client_a4749bbe_7e11_45e9_9d12_ac5d30d39aea_503e9e6"
    }
};